const devConfig = {
    backendV2BaseUrl: 'https://backend-v2.idana.dev/api/v1',
    auth0: {
        redirectUri: 'https://admin.idana.dev/callback',
        clientId: 'NMG5IGfNwwphOhWvZqYn85rkh5t4nDhv',
        domain: 'idana-dev.eu.auth0.com',
        audience: 'https://backend-dev.tomes.gmbh',
    },
    stripeSubscriptionBaseUrl: 'https://dashboard.stripe.com/test/subscriptions',
    stripeCustomerBaseUrl: 'https://dashboard.stripe.com/test/customers',
};
export default devConfig;
